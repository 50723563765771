loadjs.ready(["slick"], {
  success: function () {
    $(document).ready(function () {
      $(".slick-slider").not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        swipeToSlide: true,
        edgeFriction: 0.85,
        centerPadding: "150px",
        waitForAnimate: false,
        mobileFirst: true,
        dots: false,
        nextArrow:
          '<button type="button" class="slick-next"><svg class="svg-icon" viewBox="0 0 20 20"><path fill="none" d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path></svg></button>',
        prevArrow:
          '<button type="button" class="slick-prev"><svg class="svg-icon" viewBox="0 0 20 20"><path fill="none" d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path></svg></button>',
        cssEase: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
        speed: 500,
        responsive: [
          {
            breakpoint: 608,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: "100px",
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              centerMode: true,
              centerPadding: "100px",
            },
          },
          // {
          //     breakpoint: 1000,
          //     settings: {
          //         slidesToShow: 2,
          //         slidesToScroll: 1,
          //         centerMode: true,
          //         centerPadding: '100px',
          //     }
          // },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              centerMode: false,
              centerPadding: "150px",
              waitForAnimate: true,
              draggable: false,
              swipeToSlide: false,
            },
          },
        ],
      });
      $(".testimonial-story__slider").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        swipeToSlide: true,
        edgeFriction: 0.85,
        centerPadding: "150px",
        waitForAnimate: false,
        mobileFirst: true,
        dots: true,
        cssEase: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
        speed: 500,
        autoplay: true,
      });
      $('.tools-resources-container').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        swipeToSlide: false,
        swipe: false,
        edgeFriction: 0.85,
        centerPadding: '150px',
        waitForAnimate: false,
        mobileFirst: true,
        fade: true,
        dots: false,
        asNavFor: '.tools-slider-menu',
      });
      $('.homepage-resources-slider').slick({
        centerMode: true,
        centerPadding: '22px',
        slidesToShow: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 400,
            settings: {
              centerMode: true,
              centerPadding: '22px',
              slidesToShow: 1,
              arrows: false,
              dots: false,
              autoplay: true,
              autoplaySpeed: 5000,
              mobileFirst: true,
            }
          },
          {
            breakpoint: 801,
            settings: {
              slidesToShow: 2,
              centerPadding: '40px',
            }
          },
          {
            breakpoint: 900,
            settings: 'unslick'
          }
        ]
      });
      $('.testimonial-bubble-slider').slick({
        centerMode: true,
        centerPadding: '22px',
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 400,
            settings: {
              centerMode: true,
              centerPadding: '22px',
              slidesToShow: 1,
              arrows: false,
              dots: true,
              autoplay: true,
              autoplaySpeed: 5000,
              mobileFirst: true,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 1,
              centerPadding: '10px',
            }
          },
          {
            breakpoint: 900,
            settings: 'unslick'
          }
        ]
      });
      $('.tools-slider').slick({
        centerMode: true,
        centerPadding: '22px',
        slidesToShow: 3,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '30px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 1
            }
          }
        ]
      });
      $('.tools-slider-menu').slick({
        slidesToShow: 5,
        slidesToScroll: 15,
        centerMode: true,
        swipeToSlide: false,
        swipe: false,
        edgeFriction: 0.85,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        asNavFor: '.tools-resources-container',
      });

      $(".slick-slider").show();
      $(".testimonial-story__slider").show();
      $(".tools-slider").show();
      $(".tools-resources-container").show();
      $(".homepage-resources-slider").show();
      $(".testimonial-bubble-slider").show();
      $(".tools-slider-menu").show();
    });
  },
});
